import React from "react"

import SingleMindedIcon from "./../assets/images/svg/single-minded-icon.svg"
import BrandNarrativeIcon from "./../assets/images/svg/brand-narrative-icon.svg"
import DistinctIcon from "./../assets/images/svg/distinct-icon.svg"
import ConsistentIcon from "./../assets/images/svg/consistent-icon.svg"
import ClearIcon from "./../assets/images/svg/clear-icon.svg"
import EsgIcon from "./../assets/images/svg/esg-icon.svg"
import AutheticIcon from "./../assets/images/svg/authetic-icon.svg"
import MessagingIcon from "./../assets/images/svg/messaging-icon.svg"
import VisualIcon from "./../assets/images/svg/visual-icon.svg"
import DigitalIcon from "./../assets/images/svg/digital-icon.svg"

export const methodologyAccordion = [
  {
    id: 0,
    title: "Single-minded, overarching brand idea",
    content:
      "<p>The brand idea sits at the heart of the brand; encapsulating what it stands for in one simple, meaningful phrase. It serves as a ‘red thread’, influencing and tying together other key brand components (e.g. purpose, personality, values and proposition). In our new normal, with trust being more important than ever, it’s vital that a core brand idea is ownable and authentic, reflecting an organisation’s truths, attitudes and uniqueness.</p><p>One would expect with a single-minded, overarching idea, consistency in delivery is key. The strongest brand ideas are instantly recognisable and expressed both visually and verbally throughout the brand identity.<h3 style='text-decoration:underline'>Success criteria</h3><p>In benchmarking success in this area, we are looking for the following:</p><ul><li>An obvious ‘red thread’ running throughout the brand narrative and creative expression</li><li>A core idea that is succinct and easy to understand</li><li>A distinctive idea that is ownable and stands out from the crowd</li><li>A clear link to other brand components</li></ul><h3 style='text-decoration:underline'>Common weaknesses</h3><p>In assessing weakness in this area, we have observed the following common pitfalls:</p><ul><li>A generic phrase or common expression (e.g. ‘Leading the way’) serving as the brand idea</li><li>A ‘thin’ or disconnected strapline/slogan posing as the brand idea</li><li>A high-level statement that resonates with some audiences, but not with the wider stakeholder group</li></ul>",
    image: "",
    icon: <SingleMindedIcon />,
  },
  {
    id: 1,
    title: "Coherent brand narrative across business units",
    content:
      "<p>A brand narrative captures the story of an organisation – where it comes from, why it exists, and where it’s going. The strongest brand narratives are built on and reflect a solid brand foundation (e.g. brand idea, purpose, proposition, personality and values) but also speak to what’s happening in the outside world. Referencing challenges posed by the external context and the company’s role in addressing those challenges is a great way to maintain and build relevancy.</p><p>The most impactful brand narratives resonate across an organisation by focusing on points of commonality rather than differences often found at the business unit or geographical level.</p><h3 style='text-decoration:underline'>Success criteria</h3><p>In benchmarking success in this area, we are looking for the following:</p><ul><li>A narrative evident across communications touchpoints</li><li>A narrative connected to and inspired by the overarching brand idea</li></ul><h3 style='text-decoration:underline'>Common weaknesses</h3><p>In assessing weakness in this area, we have observed the following common pitfalls:</p><ul><li>An isolated ‘About us’ description or company boilerplate posing as a brand narrative</li><li>An inconsistent narrative ‘reinvented’ by marketeers and brand managers for different parts of the business</li></ul>",
    image: "",
    icon: <BrandNarrativeIcon />,
  },
  {
    id: 2,
    title: "Clear and compelling purpose",
    content:
      "<p>An organisation’s purpose explains why it exists beyond profit. It looks beyond shareholders by focusing on wider stakeholder value creation and the role the organisation plays in wider society.</p><p>Although purpose has been on the agenda for quite some time now, the COVID-19 pandemic is shining an intense spotlight on the truly purposeful companies versus the ‘purpose-washers’. The companies getting purpose ‘right’ are treating it as a transformational journey delivered through clear actions and behaviours rather than a copywriting and marketing exercise for existing initiatives.</p><h3 style='text-decoration:underline'>Success criteria</h3><p>In benchmarking success in this area, we are looking for the following:</p><ul><li>A statement that speaks to ‘why’ rather than ‘what’</li><li>An aspirational and ownable ‘north star’ that is also achievable and realistic</li><li>A statement that speaks to all stakeholders and inspires change</li></ul><h3 style='text-decoration:underline'>Common weaknesses</h3><p>In assessing weakness in this area, we have observed the following common pitfalls:</p><ul><li>A proposition or USP (unique selling point) posing as a purpose statement</li><li>A lofty purpose statement (e.g. ‘We exist to save the world’)</li><li>A generic purpose statement (e.g. ‘We exist to serve our customers’)</li><li>A well-written statement that lives solely in the Annual Report but is not embedded within the organisation</li></ul>",
    image: "",
    icon: <ClearIcon />,
  },
  {
    id: 3,
    title: "Clear ESG strategy driven by purpose",
    content:
      "<p>As businesses build back better, people are looking beyond profit for signals of success. Being good and doing good now go hand in hand.</p><p>COVID-19 has hugely affected some mega-trends within sustainability, such as the awareness of our connection with nature and permanent changes to the way we work. Now more than ever, corporate brands are facing unparalleled pressure to promote a credible, greener and more sustainable story.</p><p>Environmental, Social and Governance (ESG) strategies identify the company’s most material issues and set out a clear plan to manage these as opportunities and risks on behalf of its stakeholders. Companies with a clear ESG strategy driven by an overarching purpose will be in a far stronger position as we continue to recover from the impacts of the pandemic.</p><h3 style='text-decoration:underline'>Success criteria</h3><p>In benchmarking success in this area, we are looking for the following:</p><ul><li>A clear link between the company’s purpose and ESG strategy</li><li>A mix of quantitative and qualitative targets with clear dates</li><li>An established and transparent approach to measurement and data disclosure</li></ul><h3 style='text-decoration:underline'>Common weaknesses</h3><p>In assessing weakness in this area, we have observed the following common pitfalls:</p><ul><li>Treating sustainability as a box-ticking exercise rather than putting it front and centre of the brand experience</li><li>Disparate, one-off activities (e.g. charitable giving) with no overarching strategy and no reference to the wider impact of a brand’s activity on the social and natural world</li></ul>",
    image: "",
    icon: <EsgIcon />,
  },
  {
    id: 4,
    title: "Authentic and ownable values",
    content:
      "<p>Values reflect the beliefs and principles that guide an organisation. They are obviously key for internal culture, appraisal and remuneration, but for other stakeholders, such as customers and partners, values can provide insight into ways of working and cultural alignment. An effective set of values can go a long way in driving positive customer interaction and brand perception.</p><p>While values need to be aspirational, they need to be meaningful and realistic within an organisation’s capabilities. Values also need to be clear, easy to understand and relatable for current (and prospective) employees.</p><p>The success of brand values lies far beyond a series of posters on the office wall. Values should have a clear and evident link to the company’s overarching purpose and help to impact everyday employee behaviour, in every role, at every level.</p><h3 style='text-decoration:underline'>Success criteria</h3><p>In benchmarking success in this area, we are looking for the following:</p><ul><li>A distinctive and memorable values set</li><li>A clear link to other brand components, especially purpose</li><li>Values that are relevant and grounded in the real world</li><li>Values that inspire action and drive everyday behaviour</li></ul><h3 style='text-decoration:underline'>Common weaknesses</h3><p>In assessing weakness in this area, we have observed the following common pitfalls:</p><ul><li>A generic set of values (e.g. ‘Quality’, ‘Integrity’, ‘Excellence’)</li><li>Too many values, making them hard to remember</li><li>High-level values that lack detail and may be hard to action (e.g. ‘Be innovative’)</li><li>A top-down approach to developing and embedding values within an organisation</li></ul>",
    image: "",
    icon: <AutheticIcon />,
  },
  {
    id: 5,
    title: "Distinct and engaging personality",
    content:
      "<p>Brand personality is the personification of an organisation’s brand. Brand personality attributes are not often explicitly stated but are evident in the brand’s visual and verbal identity.</p><p>While an engaging personality is obviously vital for consumer-facing brands, it is also important for B2B organisations as a key point of differentiation. There are several different models for assessing and defining brand personality. We find a simple and effective approach is to identify a dominant and secondary brand archetype for an organisation, and then to define corresponding personality attributes for each archetype. For example, an organisation that considers itself a challenger brand might identify best with the ‘outlaw’ (e.g. independent, thought-provoking, disruptive) and ‘explorer’ (e.g. pioneering, curious) archetypes.</p><p>It is also very important that a brand’s personality supports and complements other brand components. For instance, if a brand value emphasises ‘collaboration’ and ‘partnership’, a ‘challenging’ and ‘independent’ brand personality might feel at odds.</p><h3 style='text-decoration:underline'>Success criteria</h3><p>In benchmarking success in this area, we are looking for the following:</p><ul><li>A distinct personality derived from brand archetypes</li><li>A brand personality evident in the visual and verbal brand identity</li><li>A clear link to other brand components</li></ul><h3 style='text-decoration:underline'>Common weaknesses</h3><p>In assessing weakness in this area, we have observed the following common pitfalls:</p><ul><li>Generic phrases (e.g. ‘global’, ‘leading’) posing as brand personality</li><li>A personality that taps into too many archetypes at once</li><li>A personality that feels inauthentic and unattainable</li><li>A personality that feels at odds with other brand components</li></ul>",
    image: "",
    icon: <DistinctIcon />,
  },
  {
    id: 6,
    title: "Tailored proposition and messaging for each stakeholder group",
    content:
      "<p>A brand proposition is what an organisation uniquely offers its audiences versus the competition.</p><p>Proposition is perhaps the most familiar, straightforward and easy to define brand component, as organisations often already know their USPs when setting up a business. However, where it gets trickier is developing nuanced propositions and messaging for distinct stakeholder groups. The key lies in first developing an overarching brand proposition that resonates across stakeholders and then tailoring individual proposition statements for each group (employees, partners, investors, communities, customers).</p><p>Employee Value Proposition (EVP) is a great example of this. The most impactful EVPs are inspired by and connected to a company’s overarching brand proposition but speak overtly to employee benefits and reasons to join a company.</p><h3 style='text-decoration:underline'>Success criteria</h3><p>In benchmarking success in this area, we are looking for the following:</p><ul><li>An overarching brand proposition that resonates across stakeholders, supported by tailored propositions for each stakeholder group</li><li>Proposition statements that speak to both functional and emotional benefits</li><li>A clear link to other brand components</li></ul><h3 style='text-decoration:underline'>Common weaknesses</h3><p>In assessing weakness in this area, we have observed the following common pitfalls:</p><ul><li>Proposition statements that use generic language (e.g. ‘leading’, ‘established’, ‘innovative’, ‘customer-orientated’, ‘reliable’)</li><li>Proposition statements that only speak functionality and disregard ‘softer’ benefits (e.g. collaboration, agility, partnership)</li></ul>",
    image: "",
    icon: <MessagingIcon />,
  },
  {
    id: 7,
    title: "Standout visual style",
    content:
      "<p>A beautifully designed visual identity doesn’t just support a brand; it elevates it. So why do some brands stand out and others get lost in the crowd?</p><p>While every visual style is clearly underpinned by a common toolkit – fonts, colours, image style, etc. – the brightest brands use the sum of those parts to create something distinctive that captures the imagination and interest. Standout brands have a certain élan about them, an attitude that demands to be looked at and listened to.</p><p>A potent combination of visual and verbal identity that tells a memorable brand story is vital in creating real cut-through. Stunning quality imagery is a given, but whereas brands reliant on cosmetic styling might intrigue, it is those that combine both editorial and visual flair that will resonate, grab attention and drive genuine engagement.</p><h3 style='text-decoration:underline'>Success criteria</h3><p>In benchmarking success in this area, we are looking for the following:</p><ul><li>Visual flair with a real point of difference from sector expectations</li><li>A powerful combination of visual and verbal identity that grabs attention and demands further interest</li></ul><h3 style='text-decoration:underline'>Common weaknesses</h3><p>In assessing weakness in this area, we have observed the following common pitfalls:</p><ul><li>Safe visual styling that sits comfortably within the sector norm</li><li>Poor-quality assets and implementation</li><li>Lack of storytelling</li></ul>",
    image: "",
    icon: <VisualIcon />,
  },
  {
    id: 8,
    title: "Consistent use of brand identity",
    content:
      "<p>Brand identity is how a brand presents and expresses itself both visually and verbally. The strongest identities are influenced and inspired by the brand’s personality and overarching brand idea. They are instantly recognisable, distinctive and consistently applied across all comms touchpoints.</p><p>Simply put, visual brand identity is what an organisation looks like; a brand’s visual ‘toolkit’ is typically composed of fonts, colour palette, logo, and illustration and photography style. Verbal brand identity is what an organisation sounds like; this is captured in an organisation’s tone, language, messaging and naming approach.</p><p>It is important that both of these go hand in hand with each other. Unfortunately, verbal identity is often overlooked, which leads to organisations defaulting to generic language and overused ‘corporate’ phrases.</p><p>A strong visual identity instantly communicates who a business is, what it is about, and why people should want to interact with it. But if an organisation’s visual identity is disconnected, inconsistent, or an inaccurate reflection of its brand, it isn’t telling a cohesive brand story. That makes it harder for a brand to connect with and establish a relationship with the people it’s trying to reach.</p><h3 style='text-decoration:underline'>Success criteria</h3><p>In benchmarking success in this area, we are looking for the following:</p><ul><li>A visual and verbal identity that go hand in hand with each other</li><li>Consistent use of visual brand assets across comms touchpoints</li><li>Consistent tone and language across comms touchpoints</li></ul><h3 style='text-decoration:underline'>Common weaknesses</h3><p>In assessing weakness in this area, we have observed the following common pitfalls:</p><ul><li>A visual brand identity that feels disconnected from the rest of the brand</li><li>Inconsistent application of visual brand assets</li><li>No established verbal identity</li></ul>",
    image: "",
    icon: <ConsistentIcon />,
  },
  {
    id: 9,
    title: "Seamless digital brand experience",
    content:
      "<p>With more and more brands being ‘digital first’, a seamless online experience is more critical than ever. A corporate website is usually the primary channel for connecting with a wide stakeholder base, so it should capture the essence of an organisation’s brand story and deliver a positive and persuasive brand experience.</p><p>Digital provides the opportunity for interactive storytelling, with film and animation offering exciting channels for conveying brand purpose, values and personality. Every opportunity should be taken to make the experience ‘sticky’, driving ongoing engagement and brand advocacy.</p><p>It’s important to remember that every aspect of engagement with a corporate website impacts viewer perception of an organisation’s brand. We have reviewed the digital experience and assessed the overall ‘brand experience’. This includes benchmarking areas such as the interface design, brand narrative, user experience and ease of navigation, as well as more technical areas such as the execution of responsive layouts.</p><h3 style='text-decoration:underline'>Success criteria</h3><p>In benchmarking success in this area, we are looking for the following:</p><ul><li>Consistent application of visual and verbal brand identity</li><li>An immediate sense of the brand story brought to life through rich, interactive digital content</li><li>A seamless and accessible user experience that will enhance positive reputation</li></ul><h3 style='text-decoration:underline'>Common weaknesses</h3><p>In assessing weakness in this area, we have observed the following common pitfalls:</p><ul><li>Outdated design and content that is not on brand</li><li>No evidence of a clear brand story or narrative</li><li>Lack of storytelling</li><li>A poor responsive experience across desktop, tablet and mobile</li><li>Confusing navigation without clear calls to action</li></ul>",
    image: "",
    icon: <DigitalIcon />,
  },
]
