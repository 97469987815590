import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Container from "./../components/Container"
import Wysiwyg from "./../components/Wysiwyg"
import { InnerContainer } from "./../components/InnerContainer"
import AccordionList from "../components/Accordion/AccordionList"

import { methodologyWysiwyg } from "./../mocks/methodologyWysiwyg"
import { methodologyAccordion } from "./../mocks/methodologyAccordion"

const MethodologyPage = () => (
  <Layout>
    <SEO
      title="Our methodology B2B’s Brightest Brands"
      description="Our Strategy & Insights team identified the largest privately owned and publicly listed companies in the UK."
    />
    <Container bg="white">
      <InnerContainer>
        <Container maxWidth="680px" pb={["68px", "12px", "78px"]}>
          <Wysiwyg>
            <div dangerouslySetInnerHTML={{ __html: methodologyWysiwyg }} />
          </Wysiwyg>
        </Container>
        <Container overflow="hidden">
          <AccordionList
            isAlt
            heading="Criteria"
            items={methodologyAccordion}
          />
        </Container>
      </InnerContainer>
    </Container>
  </Layout>
)

export default MethodologyPage
